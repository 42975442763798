.event-card {
  border-bottom: 1px solid lighten($grey, 30%);
  margin-bottom: 20px;
  padding-bottom: 20px;

  a {
    text-decoration: none;
    display: grid;
    grid-template-columns: 35% 60%;
    justify-content: space-between;
  }

  &__left {
    img {
      display: block;
      width: 100%;
    }
  }

  &__right {
    h3 {
      color: $gold;
      font-family: 'Oswald-Regular';
      font-size: 20px;
      margin-top: 0;
      margin-bottom: 0;
      line-height: 1.3;
    }

    p {
      color: $dark-grey;
      font-family: 'CharlotteSansMediumPlain';
      margin-top: 0;
      text-transform: uppercase;    
      margin-bottom: 0;
      font-size: 14px;
    }
  }
}