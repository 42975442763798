.upcoming {
  &__events {
    background: $white;
    padding: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .upcoming {
    &__events {
      grid-gap: 0;
      grid-template-columns: none;
      padding: 20px;
    }
  }
}