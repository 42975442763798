.seating-chart {
  background: $white;
  min-height: 100%;
  // position: relative;

  &__custom {
    display: block;
    width: 100%;
  }

  &__overlay {
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, .4);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    left: 0;
    overflow: scroll;
    padding: 20px;
    position: absolute;
    top: 0;
    z-index: 10;

    img {
      display: block;
      // height: auto;
      width: 90%;
      margin: 20px auto;
    }
  }

  .page-title {
    width: 63%;
  }

  .selectWrapper {
    margin: -5px 0 0 15px;
    width: 60%;
  }

  .sectionRight {
    border-left: 1px solid #eeeeee;
    position: absolute;
    right: 0;
    top: 61px;
    width: 37%;
  }

  .footer {
    // position: absolute;
    width: 100%;
    // bottom: 0;
  }

  &__canvas {
    padding: 50px 30px 20px;

    img {
      display: block;
      width: 100%;
    }
  }

  &__sections {
    max-height: 145px;
    overflow-y: scroll;
  }

  &__body {
    margin-top: 25px;
    width: 63%;

    div {
      svg {
        g {
          text {
            fill: $white;
            font-family: 'CharlotteSansMediumPlain';
            font-size: 14px;
          }
          &.small-section {
            text {
              font-size: 8px !important;
              &.small-section {
                &__number {
                  fill: $grey;
                }
              }
            }
            &.focused {
              text {
                &.small-section {
                  &__number {
                    fill: #c41230;
                  }
                }
              }
            }
          }
          &.focused {
            rect,
            polygon,
            path {
              fill: #c41230;
            }
          }
          rect,
          polygon,
          path {
            fill: $grey;
          }

          &#hockey_rink {
            path,
            rect {
              fill: none !important;
              stroke: $black;
            }

            text {
              stroke: $black;
              font-size: 12px;
            }
          }

          &#STAGE,
          &#mix {
            rect {
              fill: none;
              stroke: $black;
            }
            text {
              fill: $black;
            }
          }

          &#UNAVAILABLE {
            polygon,
            rect,
            path {
              fill: none;
            }
          }
        }

        #handicapped {
          path {
            fill: $white;
            stroke: $blue;
          }
          g {
            g {
              circle {
                fill: $white;
              }
            }
          }
        }

        #court {
          rect {
            fill: $white;
            stroke: $black;
          }
          circle {
            stroke: $black;
            fill: rgba(0, 0, 0, 0);
          }
          path {
            fill: none;
            stroke: $black;
          }
          line {
            stroke: $black;
            fill: $black;
          }
          text {
            fill: $black;
          }
        }

        #stage {
          rect {
            fill: $white;
            stroke: $black;
          }
          text {
            fill: $black;
          }
        }
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  &__search {
    appearance: none;
    border-bottom: 1px solid lighten($grey, 30%);
    border-radius: 0;
    border-top: 1px solid lighten($grey, 30%);
    border: 0;
    font-size: 18px;
    margin: 0;
    padding: 15px 20px;
    width: 100%;

    &-box {
      display: flex;

      .clearSearch {
        img {
          display: inline-block;
          max-height: 15px;
          max-width: 15px;
          padding-left: 0;
        }
      }

      span {
        align-items: center;
        border: 1px solid lighten($grey, 30%);
        border-right: 0;
        cursor: pointer;
        display: flex;
        padding: 0 20px;

        img {
          max-height: 20px;
          padding-left: 10px;
        }
      }
    }

    &:focus {
      outline: none;
    }
  }

  &__section {
    border-bottom: 1px solid lighten($grey, 30%);
    font-family: 'CharlotteSansMediumPlain';
    padding: 15px 20px;

    &.active {
      background: $pale-red;
      color: $red;
    }
  }
}

.selectWrapper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
  margin: -10px auto 15px;
  width: calc(100% - 60px);

  #selector {
    div {
      border-radius: 0;
      box-shadow: none;
      line-height: 40px;
      min-height: 40px;

      .css-tj5bde-Svg {
        margin-top: 3px;
      }

      input {
        &:focus {
          outline: none;
        }
      }
    }
  }
}

@media(max-width: 768px) {
  .seating-chart {
    .page-title {
      width: auto;
    }

    .selectWrapper {
      margin: -5px auto 0;
      width: calc(100% - 60px);
    }

    .sectionRight {
      border-left: 0px solid #eeeeee;
      position: relative;
      right: 0;
      top: 0;
      width: auto;
    }

    .footer {
      position: relative;
      width: auto;
    }

    &__body {
      margin-top: 14px;
      width: auto;
    }
  }
}