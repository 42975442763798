.carousel {
  text-align: center;

  &.absolute {
    position: absolute;
  }

  &__body {
    background: rgba(0, 0, 0, .5);
    position: relative;
    z-index: 4;
  }

  &__top {
    color: $gold;
    font-family: 'Oswald-Regular';
    font-size: 24px;
    margin-top: 0;
    padding: 20px 20px 0;
    text-transform: uppercase;

    .white {
      color: $white;
    }
  }

  &__image {
    width: 100%;

    img {
      display: block;
      margin: 0 auto;
      width: 80%;
    }
  }

  &__copy {
    color: $white;
    font-family: 'Oswald-Regular';
    margin-bottom: 0;
    margin: 20px auto 10px;
    text-align: left;
    text-transform: uppercase;
  }

  &__date {
    color: $gold;
    font-family: 'Oswald-Bold';
    font-size: 28px;
    font-weight: bold;
    margin: 0 auto 10px;
    text-align: left;
    text-transform: uppercase;
  }

  &__indicators {
    display: flex;
    justify-content: space-around;
    margin: 30px auto 0;
    width: 60%;
  }

  &__indicator {
    background: $grey;
    cursor: pointer;
    display: block;
    height: 5px;
    margin-bottom: 30px;
    width: 25%;
  }
}

.landing__slider {
  margin-bottom: 0 !important;
  width: 63%;

  .home-slide {
    position: relative;

    div.blurred-image {
      background-position-x: center;
      background-size: cover;
      filter: blur(25px);
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: -1;
    }

    .content {
      background: rgba(0, 0, 0, .5);
      padding: 10px 15px;
      padding-bottom: 70px;

      h1 {
        color: $gold;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;

        strong {
          color: $white;
          font-weight: 500;
        }
      }

      .slide {
        &__image {
          padding: 10px 15px;
          position: relative;

          &__mobile {
            display: none;
          }

          img {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5);
            width: 100%;
          }

          .liveEvent {
            display: block;
            font-family: 'Charlotte Sans';
            left: 35px;
            position: absolute;
            top: 30px;
            width: calc(100% - 70px);

            a {
              background-color: rgba(255, 255, 255, .5);
              display: block;
              font-size: 14px;
              letter-spacing: 1.3px;
              line-height: 1;
              text-decoration: none;
              text-transform: uppercase;
            }

            span {
              color: $white;
              text-decoration: none;
              vertical-align: -2px;
            }

            .badge {
              background-color: $red;
              color: $white;
              display: inline-block;
              font-family: 'Charlotte Sans';
              font-size: 14px;
              height: 26px;
              letter-spacing: 1px;
              line-height: 26px;
              margin-right: 2px;
              text-align: center;
              text-transform: uppercase;
              width: 46px;
            }

            i {
              color: $white;
              font-size: 12px;
              position: absolute;
              right: 6px;
              top: 7px;
            }
          }
        }

        &__footer {
          padding: 0 35px;

          p {
            color: $white;
            font-size: 21px;
            font-weight: 600;
            margin-bottom: 0;
            text-transform: uppercase;
          }

          h2 {
            color: $gold;
            font-size: 25px;
            font-weight: 600;
            line-height: 35px;
            text-transform: uppercase;
          }

          &.sports {
            padding: 0 25px;

            .slideFooterLeft {
              border-right: solid 2px #ccc;
              display: inline-block;
              width: 70%;

              .team {
                color: #535353;
                font-weight: 500;
                position: relative;

                .teamLogo {
                  background-position: center center;
                  background-repeat: no-repeat;
                  background-size: contain;
                  display: inline-block;
                  height: 30px;
                  margin-right: 5px;
                  width: 30px;

                  // &.tcu {
                  //   background-image: url(../assets/img/teams/1200px-TCU_Horned_Frogs_logo.png);
                  // }

                  // &.usc {
                  //   background-image: url(../assets/img/teams/USC_Trojans_logo.png);
                  // }
                }

                .teamName {
                  vertical-align: 7px;
                }

                .teamScore {
                  line-height: 32px;
                  margin-right: 12px;
                  vertical-align: 7px;
                }

                &.winner::after {
                  color: $red;
                  content: '\f0d9';
                  font-family: 'Font Awesome 5 Free';
                  font-weight: 900;
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }

            .slideFooterRight {
              display: inline-block;
              padding: 5px 0;
              text-align: center;
              width: 30%;

              p {
                color: #535353;
                font-family: 'Oswald';
                font-size: 18px;
                line-height: 1.1;
              }

              &.liveScore p {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }

  .slick-dots {
    bottom: 35px;

    li {
      height: 4px;
      width: 50px;

      button {
        background: rgba(255, 255, 255, .3);
        height: 4px;
        padding: 0;
        width: 50px;

        &::after,
        &::before {
          display: none;
        }
      }

      &.slick-active button {
        background: rgba(255, 255, 255, 1);
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .landing__slider {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .landing__slider {
    .home-slide {
      .content {
        .slide {
          &__footer {
            padding: 0 15px;
          }
          
          &__image {
            &__mobile {
              display: block;
            }

            &__tablet {
              display: none;
            }
          }
        }
      }
    }

    .slick-dots {
      bottom: 45px;
    }
  }

  .carousel {
    &__top {
      font-size: 20px;
      margin-bottom: 0;
      padding-top: 0;
    }

    &__copy {
      margin-top: 0;
    }

    &__date {
      font-size: 24px;
    }
  }
}
