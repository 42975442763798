.footer {
  background: url('/assets/img/footer-bg.jpg');
  background-size: cover;
  clear: both;
  color: $white;
  font-family: 'CharlotteSansMediumPlain';
  text-align: center;

  &__col {
    display: inline-block;
    font-size: 17px;
    margin: 0 4.5%;
    vertical-align: middle;
    width: auto;
  }

  &__logo {
    padding: 10px 20px 20px;

    img {
      margin-top: 10px;
      max-width: 100%;
      width: 150px;
    }
  }

  &__phone,
  &__address {
    padding-bottom: 0;
  }

  &__social {
    padding-top: 0;

    img {
      margin: 0 15px;
      max-height: 20px;
      max-width: 20px;
    }
  }
}


@media only screen and (max-width: 1023px) {
  .footer {
    &__col {
      margin: 0 1%;
    }
  }
}

@media only screen and (max-width: 700px) {
  .footer {
    display: block;

    &__col {
      margin: 12px;
    }

    &__logo {
      padding: 0;

      img {
        margin-top: 0;
      }
    }
  }
}