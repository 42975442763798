.landing {
  background: $dark-grey;
  font-size: 20px;

  &__flex {
    display: flex;
  }

  &__body {
    background: $dark-grey;
    height: 610px;
    overflow-y: scroll;
    padding: 20px 30px 30px;
    width: 37%;

    h2 {
      color: $white;
      font-family: 'CharlotteSansMediumPlain';
      font-size: 18px;
      font-weight: normal;
      margin-bottom: 35px;
      text-transform: uppercase;
    }

    &__cards {
      display: grid;
      grid-gap: 25px 30px;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

      a {
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .landing {
    &__flex {
      flex-direction: column;
    }

    &__body {
      width: 100%;
      height: auto;
      overflow-y: auto;
    }
  }
}

@media (max-width: 400px) {
  .landing {
    &__body {
      padding: 15px;
    }
  }
}