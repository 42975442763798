.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 92vh;

  &__body {
    color: $white;
    padding: 30px;
  }
}