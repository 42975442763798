.qr-image-wrapper section { 
  border-radius: 8px;                   
}

.qr-image-wrapper section div {
  box-shadow: unset !important;     
}

.modal {
  &__qr {
    background-color: $dark-grey;
    color: $white;
    font-family: 'CharlotteSansMediumPlain';
    height: 100%;
    left: 0;
    overflow-y: scroll;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9;

    &__body {
      display: flex;
      margin: 0 auto;
      flex-direction: column;
      width: 320px;
      align-items: center;
    }
  }
}