
.landing-card {
  border-bottom: 2px solid rgba(255, 255, 255, .1);
  display: grid;
  grid-template-columns: 60% 40%;
  padding-bottom: 25px;
  width: 100%;

  &.minimal {
    align-items: center;
    grid-template-columns: 80% 20%;

    .landing-card {
      &__right {
        img {
          margin-right: 5px;
          width: 70%;
        }
      }
    }
  }

  &__left {
    h2 {
      color: $gold;
      font-family: 'Oswald-Regular';
      font-size: 20px;
      font-weight: normal;
      line-height: 1.1;
      margin-bottom: 0px;
      margin-top: 0;
    }

    p {
      color: $grey;
      font-family: 'Oswald-Regular';
      font-size: 16px;
      margin-top: 6px;
      text-transform: uppercase;
    }
  }

  &__right {
    img {
      padding-left: 10px;
      width: 100%;
    }
  }
}

@media (max-width: 480px) {
  .landing-card {
    padding: 0 0 20px;

    &__left {
      h2 {
        font-size: 20px;
      }

      p {
        font-size: 16px;
      }
    }
  }
}
