.upcoming__events {
  display: grid;
  grid-gap: 20px 15px;
  grid-template-columns: repeat(auto-fit, minmax(346px, 1fr));
}

.events {
  background: $white;

  .masthead {
    img {
      display: block;
      margin-bottom: -5px;
      min-height: 200px;
      width: 100%;
    }
  }

  .eventTitle {
    background-color: #212121;
    padding: 20px;
    position: relative;
    padding-right: 200px;

    h1 {
      color: $white;
      font-family: 'Oswald-Regular';
      font-size: 22px;
      font-weight: 500;
      margin: 0;
    }

    h2 {
      color: $gold;
      font-family: 'Oswald-Regular';
      font-size: 32px;
      font-weight: 600;
      margin: 0;
      text-transform: uppercase;
    }

    .ticketsBtnTablet {
      background-color: $white;
      color: $black;
      position: absolute;
      top: 50%;
      right: 25px;
      padding: 0px 25px;
      transform: translateY(-50%);
    }
  }

  .eventContainer {
    border: solid 1px #eee;

    .eventContent {
      background-color: $white;
      max-height: 400px;
      overflow: hidden;
      padding: 20px 30px;
      position: relative;
      transition: cubic-bezier(0.775, 0, 0.211, 1) 700ms all;
      font-family: 'CharlotteSansMediumPlain';
      display: inline-block;
      width: calc(100% - 245px);

      .btn {
        margin-bottom: 20px;
        width: 100%;
      }

      .readMore {
        bottom: 0;
        cursor: pointer;
        display: block;
        height: 105px;
        left: 0;
        pointer-events: all;
        position: absolute;
        transition: cubic-bezier(0.775, 0, 0.211, 1) 700ms all;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 1) 100%);
        width: 100%;

        span {
          bottom: 15px;
          left: 30px;
          position: absolute;
        }
      }

      &.full {
        max-height: 1500px;

        .readMore {
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    .eventsNav {
      display: inline-block;
      margin-left: 10px;
      box-shadow: none;
      vertical-align: top;

      .nav-item {
        color: $gold;
        cursor: pointer;
        display: block;
        font-family: 'Oswald-Regular';
        font-size: 19px;
        padding: 18px 0;
        text-decoration: none;

        img {
          display: inline-block;
          height: 20px;
          margin-right: 12px;
          width: 20px;
        }
      }
    }
  }

  .seperator {
    background-color: #f3f3f3;
    box-shadow: inset 0 -1px 3px 0 rgba(0, 0, 0, 0.1), inset 0 1px 3px 0 rgba(0, 0, 0, 0.23);
    display: none;
    height: 7px;
    width: 100%;
  }
}

// .seating-chart {
//   &__overlay {
//     backdrop-filter: blur(5px);
//     background: rgba(0, 0, 0, .4);
//     display: flex;
//     flex-direction: column;
//     height: 100%;
//     justify-content: space-around;
//     left: 0;
//     padding: 20px;
//     position: absolute;
//     top: 0;
//     z-index: 8;

//     img {
//       display: block;
//       height: auto;
//       width: 100%;
//     }
//   }
// }

.ohterEventSlider {
  background: $white;
  border: solid 1px $off-white;
  font-family: 'Oswald';
  padding: 20px 30px;
  text-align: center;

  h3 {
    color: $dark-grey;
    font-family: 'Oswald-Regular';
    font-size: 24px;
    margin: 0;
    padding: 25px 0;
    text-transform: uppercase;
    text-align: left;
  }

  .slick-slide {
    font-family: 'Oswald-Regular';
    padding: 0 10px;
    text-align: left;

    img {
      width: 100%;
    }

    h2 {
      color: $gold;
      font-size: 19px;
      font-weight: normal;
      line-height: 1.1;
      margin-bottom: 0;
      margin-bottom: 5px;
      margin-top: 10px;
      text-transform: capitalize;
    }

    p {
      color: $grey;
      font-size: 14px;
      margin: 0;
      text-transform: uppercase;
    }

    a {
      text-decoration: none;
    }
  }

  .btn {
    margin: 20px 0;
  }
}

.eventExtras {
  padding: 30px;
  padding-top: 20px;

  .twoCols {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .top {
    h2 {
      color: $gold;
      display: inline-block;
      font-family: 'Oswald-Regular';
      font-size: 19px;
      text-decoration: none;
      font-weight: normal;
      margin: 0;
    }

    a {
      color: $grey;
      display: inline-block;
      float: right;
      font-family: 'CharlotteSansMediumPlain';
      font-size: 15px;
      font-weight: bold;
      padding-top: 7px;
      text-decoration: none;
      text-transform: uppercase;
    }
  }

  .card {
    background-color: $black;
    margin-top: 10px;
    padding: 10px 15px;

    h3 {
      color: $gold;
      font-family: 'Oswald-Regular';
      font-size: 15px;
      font-weight: normal;
      margin: 0;
    }

    p {
      color: $white;
      font-family: 'CharlotteSansMediumPlain';
      font-size: 15px;
      margin: 6px 0;
    }

    .svg-inline--fa {
      font-size: 11px;
      vertical-align: -1px;
    }
  }

  .athletesList {
    list-style-type: none;
    margin-top: 10px;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      padding: 7px;
      width: 20%;

      img {
        border-radius: 50%;
        width: 100%;
      }
    }
  }
}

.eventsNavTablet {
  display: inline-block;
  margin-left: 10px;
  box-shadow: none;
  vertical-align: top;
}

@import 'event-type/rodeo';

@media only screen and (max-width: 700px) {
  .events {
    .eventTitle {
      padding: 20px;
    }

    .eventContainer {
      .eventContent {
        display: block;
        width: 100%;
      }

      .eventsNav {
        padding: 10px 30px;
        display: block;
        margin-left: 0;
      }

      .seperator {
        display: block;
      }
    }
  }

  .otherEventSlider {
    box-shadow: none;
    padding-top: 0;
  }

  .eventsRodeo .selectWrapper {
    margin: -10px auto 0;
    width: calc(100% - 60px);
  }

  .scheduleWrapper {
    .mastheadContainer {
      background-color: $white;

      .eventExtras {
        .card {
          background-color: $black;

          p {
            color: $white;
          }
        }
      }
    }
  }
}
