.concessions {
  background: $off-white;

  &__sub-header {
    background: $white;
    font-family: 'CharlotteSansBoldPlain';
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__qr {
    &__icon {
      color: $gold;
      cursor: pointer;
      font-size: 2em !important;
      margin-left: 20px;
    }
  }

  &__select {
    margin: 15px auto;
    // box-shadow: 5px 5px 15px $grey, -5px -5px 15px $off-white;
  }

  &__options {
    // width: 63%;
    margin-top: -20px;
    margin-bottom: 15px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      width: auto;
      text-align: center;

      li {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
        display: inline-block;
        background-color: $white;
        padding: 12px 0px;
        margin: 0 15px;
        line-height: 1;
        width: 180px;
        font-weight: 500;
        color: rgba(40, 40, 41, 0.3);
        transition: all 200ms linear;
        cursor: pointer;

        &:hover {
          color: rgba(40, 40, 41, 0.6);
        }

        &.active {
          color: $dark-grey;
        }
      }
    }
  }

  &__body {
    // margin-top: 25px;
    width: 100%;
  }

  &__list {
    background: $white;

    &__items {
      padding: 0 15px;
    }

    &__item {
      border-bottom: 1px solid lighten($grey, 30%);
      cursor: pointer;
      font-family: 'Oswald-Regular';
      font-size: 18px;
      padding: 14px 20px 16px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all .25s ease;

      &.includes-searched {
        background: $gold;
        color: $white;
      }
    }

    &__chevron {
      font-weight: normal;
      color: lighten($dark-grey, 35%);
    }
  }

  &__search {
    appearance: none;
    border-bottom: 1px solid lighten($grey, 30%);
    border-radius: 0;
    border-top: 1px solid lighten($grey, 30%);
    border: 0;
    font-size: 18px;
    margin: 0;
    padding: 15px 20px;
    width: 100%;

    &-box {
      display: flex;
      border-bottom: 1px solid lighten($grey, 30%);

      .clearSearch {
        background: $white;

        img {
          display: inline-block;
          max-height: 15px;
          max-width: 15px;
          padding-left: 0;
        }
      }

      input {
        padding: 20px 30px;
      }

      span {
        align-items: center;
        background: #d7d7d9;
        border-left: 1px solid lighten($grey, 30%);
        // border-right: 0;
        cursor: pointer;
        display: flex;
        padding: 0 20px;

        img {
          max-height: 20px;
          padding-left: 10px;
        }
      }
    }

    &:focus {
      outline: none;
    }
  }
}

@media only screen and (max-width: 700px) {
  .concessions {
    &__search-box {
      input {
        font-size: 16px;
        padding: 14px 30px;
      }
    } 

    &__list {
      &__item {
        font-size: 16px;
      }
    }
  }
}