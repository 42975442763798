.modal {
  &__message {
    background-color: rgba(40, 40, 41, 0.5);
    height: 100%;
    position: absolute;
    font-family: 'CharlotteSansMediumPlain';
    width: 100%;
    z-index: 9;
    top: 0;
    padding: 30px;

    &__box {
      // margin-top: 30%;
      background: $white;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
      left: 50%;
      margin: 0 auto;
      max-width: 475px;
      padding: 30px;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%),
    }

    &__close {
      border: 2px solid $dark-grey;
      cursor: pointer;
      text-align: center;
      width: 100px;
      padding: 10px 15px;
      margin: 20px auto 0;
    }
  }

  &__info {
    background-color: $dark-grey;
    color: $white;
    font-family: 'CharlotteSansMediumPlain';
    height: 100%;
    left: 0;
    overflow-y: scroll;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9;

    h2 {
      font-family: 'Oswald-Regular';
      margin-top: 0;
      text-transform: uppercase;
    }

    &__close {
      cursor: pointer;
      float: right;
      height: 40px;
      width: 40px;
    }
  }

  &__concourse {
    &__sections {
      display: flex;
      flex-direction: column;
    }

    &__section {
      margin-bottom: 25px;
      padding-left: 10px;

      &--title {
        font-family: 'Oswald-Regular';
        font-size: 18px;
        font-weight: bold;
        text-transform: capitalize;
      }

      &--items {
        margin: 0;
        padding-left: 30px;
        padding-top: 10px;
      }

      &--item {
        // font-family: 'Oswald-Regular';
        // font-family: 'CharlotteSansMediumPlain';
        padding-top: 7px;
        padding-left: 0;
        text-transform: uppercase;
      }
    }
  }

  &__seating {
    &__level {
      border-bottom: 1px solid $grey;
      font-family: 'Oswald-Regular';
      padding-bottom: 30px;

      img {
        margin-right: 15px;
        max-height: 15px;
      }
    }

    &__proximity {
      border-bottom: 1px solid $grey;
      font-family: 'CharlotteSansMediumPlain';
      margin: 30px 0;
      padding-bottom: 30px;

      &--section {
        margin-bottom: 25px;
        padding: 0 10px;
      }

      &--specific {
        display: block;
        padding-left: 0;
        padding-top: 10px;

        &::before {
          color: $white;
          content: '•';
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }

    &__footer {
      align-items: center;
      background: $white;
      color: $black;
      display: flex;
      justify-content: space-between;
      padding: 15px 20px;
      text-decoration: none;

      &__copy {
        font-family: 'CharlotteSansMediumPlain';

        div {
          text-transform: uppercase;
        }
      }

      img {
        display: block;
        max-height: 40px;
      }
    }
  }

  &__filter {
    background-color: $white;
    color: $black;
    font-family: 'CharlotteSansMediumPlain';
    height: 100%;
    max-width: 800px;
    padding: 30px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999;

    h2 {
      font-family: 'Oswald-Regular';
      margin-top: 0;
      text-align: center;
      text-transform: uppercase;
    }

    &__option {
      display: block;
      margin-bottom: 30px;
      width: 100%;

      input {
        cursor: pointer;
        opacity: 0;
        position: absolute;

        &:checked {
          ~ .modal__filter__check-box {
            -ms-transform: rotate(0deg) scale(1);
            -webkit-transform: rotate(0deg) scale(1);
            background-color: $gold;
            border: 2px solid $gold;
            opacity: 1;
            transform: rotate(0deg) scale(1);

            &::after {
              -ms-transform: rotate(45deg) scale(1);
              -webkit-transform: rotate(45deg) scale(1);
              background-color: transparent;
              border: solid $white;
              border-radius: 0;
              border-width: 0 2px 2px 0;
              height: 12px;
              left: 6px;
              opacity: 1;
              top: 1px;
              transform: rotate(45deg) scale(1);
              width: 6px;
            }
          }
        }
      }
    }

    &__check-box {
      -moz-transition: all .3s ease-out;
      -ms-transition: all .3s ease-out;
      -o-transition: all .3s ease-out;
      -webkit-transition: all .3s ease-out;
      background-color: transparent;
      border: 2px solid $gold;
      height: 24px;
      position: absolute;
      right: 30px;
      transition: all .3s ease-out;
      width: 24px;

      &::after {
        -moz-transition: all .3s ease-out;
        -ms-transform: rotate(0deg) scale(0);
        -ms-transition: all .3s ease-out;
        -o-transition: all .3s ease-out;
        -webkit-transform: rotate(0deg) scale(0);
        -webkit-transition: all .3s ease-out;
        border-width: 0 3px 3px 0;
        border: solid $white;
        content: '';
        height: 0;
        left: 12px;
        opacity: 1;
        position: absolute;
        top: 12px;
        transform: rotate(0deg) scale(0);
        transition: all .3s ease-out;
        width: 0;
      }
    }

    &__buttons {
      border-top: 1px solid lighten($grey, 30%);
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      padding: 30px 0;
    }

    &__button {
      border: 1px solid $black;
      cursor: pointer;
      padding: 15px 0;
      text-align: center;
      width: 45%;

      &.dark {
        background: $black;
        color: $white;
      }
    }
  }
}