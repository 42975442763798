@import './colors';
@import './fonts';
@import './animate';

* {
  box-sizing: border-box;
}

@import './containers';
@import './components/';

html {
  height: 100%;
  overflow: hidden;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  height: 100%;
  margin: 0;
  overflow: auto;
  overscroll-behavior-y: none;
}

:focus {
  outline: none;
}

#root {
  background: $dark-grey;
  min-height: 100%;
}

.app {
  height: 100%;
  margin: 0 auto;
  padding-top: 61px;
  width: 100%;
  
  &.fixedHeader {
    padding-top: 0;

    .header__top {
      background-color: transparent;
    }
  }
}

.hyphen {
  padding: 0 6px;
}

.loading {
  background: $dark-grey;
  color: $white;
  min-height: 100vh;
  padding-top: 60px;
  text-align: center;

  &.inner {
    background: inherit;
  }

  img {
    margin: 0 auto 30px;
    max-width: 50%;
  }
}

.btn {
  -moz-user-select: none;
  -ms-user-select: none;
  border-radius: 0;
  display: inline-block;
  font-family: 'CharlotteSansMediumPlain';
  font-size: 1rem;
  height: 45px;
  letter-spacing: .5px;
  line-height: 40px;
  padding: 0 .75rem;
  text-align: center;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  user-select: none;
  white-space: nowrap;

  &.btn-primary {
    background-color: $dark-grey;
    border: solid 1px $dark-grey;
    color: $white;
  }

  &.btn-outline-primary {
    border: solid 1px $dark-grey;
    color: $dark-grey;
  }

  &.btn-block {
    display: block;
  }
}
