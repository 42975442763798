.concourse-map {
  background: $white;
  min-height: 100%;
  // position: relative;

  .sectionRight {
    width: 37%;
    position: absolute;
    right: 0;
    top: 61px;
    border-left: 1px solid $off-white;
  }

  .page-title {
    width: 63%;
    padding-bottom: 40px;
  }
  
  .selectWrapper {
    display: none;
  }

  .footer {
    width: 100%;
  }

  .selectConcourseMap {
    width: 63%;
    margin-top: -20px;
    margin-bottom: 15px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      width: auto;
      text-align: center;

      li {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
        display: inline-block;
        background-color: $white;
        padding: 12px 0px;
        margin: 0 15px;
        line-height: 1;
        width: 180px;
        font-weight: 500;
        color: rgba(40, 40, 41, 0.3);
        transition: all 200ms linear;
        cursor: pointer;

        &:hover {
          color: rgba(40, 40, 41, 0.6);
        }

        &.active {
          color: $dark-grey;
        }
      }
    }
  }

  .seating-chart {
    &__sections {
      max-height: 145px;
      overflow-y: scroll;
    }
  }

  .seating-chart__body {
    div {
      svg {
        .stroke-miter-limit {
          fill: none;
          stroke-miterlimit: 10;
          stroke-width: 4;

          &.stroke {
            &__grey {
              stroke: #ededed;
            }

            &__dark-grey {
              stroke: #3d3d3d;
            }
          }
        }

        .isolation {
          isolation: isolate;
          font-size: 20px;
          fill: #8c8c8c;
          font-family: 'CharlotteSansBoldPlain';
        }

        .twenty-nine {
          font-size: 29.960620880126953px;
          fill: #3d3d3d;
          font-family: 'CharlotteSansBoldPlain'
        }

        .fifteen {
          font-size: 15px;
          fill: #3d3d3d;
          font-family: 'CharlotteSansBoldPlain';
        }

        .text-section {
          font-size: 20px;
          fill: #8c8c8c;
          font-family: 'CharlotteSansBoldPlain';
        }

        .fill {
          &__none {
            fill: none;
          }
          &__greyish {
            fill: #dcdbdb;
          }
          &__grey {
            fill: #f7f7f7;
          }
          &__white {
            fill: $white;
          }
          &__gold {
            fill: $gold;
          }
          &__yellow {
            fill: #ffcf01;
          }
          &__red {
            fill: #ce152d;
          }
        }
      }
    }

    .main-level {
      svg {
        .st0 {
          fill: #EDEDED;
        }
        .st1 {
          fill: #DCDBDB;
          &.focused {
            fill: #c41230;
          }
        }
        .st2 {
          fill: none;
          stroke: #BCBCBC;
          stroke-width: 2;
          stroke-miterlimit: 10;
        }
        .st3 {
          fill: #3D3D3D;
        }
        .st4 {
          font-family: 'CharlotteSansBoldPlain';
        }
        .st5 {
          font-size: 29.9606px;
        }
        .st6 {
          fill: $white;
        }
        .st7 {
          fill: #003767;
        }
        .st8 {
          fill: #CE152D;
        }
        .st9 {
          fill: #C41230;
        }
        .st10 {
          fill: #FFCF01;
        }
        .st11 {
          fill: none;
        }
        .st12 {
          display: none;
        }
        .st13 {
          display: inline;
        }
        .st14 {
          fill: none;
          stroke: #231F20;
          stroke-miterlimit: 10;
        }
        .st15 {
          display: inline;
          fill: none;
          stroke: #231F20;
          stroke-miterlimit: 10;
        }
        .st16 {
          display: inline;
          fill: $red;
        }
        .st17 {
          display: inline;
          fill: #A358A2;
        }
        .st18 {
          fill: #F7F7F7;
        }
        .st19 {
          fill: #8C8C8C;
        }
        .st20 {
          fill: #214497;
        }
        .st21 {
          fill: none;
          stroke: #3D3D3D;
          stroke-width: 4;
          stroke-miterlimit: 10;
        }
        .st22 {
          fill: $gold;
          &.focused {
            fill: #c41230;
          }
        }
        .st23 {
          fill: $white;
          stroke: #A45A95;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st24 {
          fill: none;
          stroke: #A45A95;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st25 {
          fill: #231F20;
        }
        .st26 {
          fill: $white;
          stroke: #3F96B4;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st27 {
          fill: none;
          stroke: #3F96B4;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st28 {
          fill: #231F20;
          stroke: #231F20;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }
        .st29 {
          fill: $white;
          stroke: #231F20;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }
        .st30 {
          fill: none;
          stroke: #D5BA8C;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st31 {
          stroke: #231F20;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        }
        .st32 {
          fill: none;
          stroke: #9F662D;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st33 {
          fill: none;
          stroke: #773DBD;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st34 {
          fill: $white;
          stroke: #EBE4CF;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st35 {
          fill: none;
          stroke: #EBE4CF;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st36 {
          fill: none;
          stroke: #9F2214;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st37 {
          fill: #1059B2;
        }
        .st38 {
          fill: #2EB1EC;
        }
        .st39 {
          fill: #4F2683;
        }
      }
    }
    .upper-level {
      svg {
        .st0 {
          fill: #EDEDED;
        }
        .st1 {
          fill: #DCDBDB;
          &.focused {
            fill: #c41230;
          }
        }
        .st2 {
          fill: none;
          stroke: #EDEDED;
          stroke-width: 4;
          stroke-miterlimit: 10;
        }
        .st3 {
          fill: none;
          stroke: #BCBCBC;
          stroke-width: 2;
          stroke-miterlimit: 10;
        }
        .st4 {
          fill: $white;
        }
        .st5 {
          fill: #003767;
        }
        .st6 {
          fill: #CE152D;
        }
        .st7 {
          fill: #C41230;
        }
        .st8 {
          fill: #FFCF01;
        }
        .st9 {
          fill: none;
          stroke: #3D3D3D;
          stroke-width: 4;
          stroke-miterlimit: 10;
        }
        .st10 {
          fill: #F7F7F7;
        }
        .st11 {
          fill: #8C8C8C;
        }
        .st12 {
          font-family: 'CharlotteSansBoldPlain';
        }
        .st13 {
          font-size: 30.0847px;
        }
        .st14 {
          fill: #214497;
        }
        .st15 {
          fill: $gold;
          &.focused {
            fill: #c41230;
          }
        }
        .st16 {
          fill: $white;
          stroke: #9F2521;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st17 {
          fill: none;
          stroke: #9F2521;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st18 {
          fill: #231F20;
        }
        .st19 {
          fill: $white;
          stroke: #A45A95;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st20 {
          fill: none;
          stroke: #A45A95;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st21 {
          fill: $white;
          stroke: #003595;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st22 {
          fill: none;
          stroke: #003595;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st23 {
          fill: #231F20;
          stroke: #231F20;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }
        .st24 {
          fill: $white;
          stroke: #FF6C0E;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st25 {
          fill: none;
          stroke: #FF6C0E;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st26 {
          fill: $white;
          stroke: #93D500;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st27 {
          fill: none;
          stroke: #93D500;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st28 {
          stroke: #231F20;
          stroke-miterlimit: 10;
        }
        .st29 {
          fill: none;
          stroke: #D5BA8C;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st30 {
          stroke: #231F20;
          stroke-width: 0.75;
          stroke-miterlimit: 10;
        }
        .st31 {
          fill: none;
          stroke: #9F662D;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st32 {
          fill: none;
          stroke: #773DBD;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st33 {
          fill: none;
          stroke: #9F2214;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st34 {
          fill: none;
          stroke: #C1C5C8;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st35 {
          fill: #231F20;
          stroke: #231F20;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 10;
        }
        .st36 {
          fill: none;
          stroke: #FED925;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st37 {
          fill: $white;
          stroke: #231F20;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }
        .st38 {
          stroke: #231F20;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }
        .st39 {
          fill: #3D3D3D;
        }
        .st40 {
          font-size: 29.9606px;
        }
        .st41 {
          fill: #1059B2;
        }
        .st42 {
          fill: #2EB1EC;
        }
        .st43 {
          fill: none;
          stroke: #002F87;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st44 {
          fill: #54B948;
          stroke: #54B948;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }
        .st45 {
          fill: #00A160;
          stroke: #00A160;
          stroke-width: 0.5;
          stroke-miterlimit: 10;
        }
        .st46 {
          fill: none;
          stroke: #231F20;
          stroke-width: 1.5;
          stroke-miterlimit: 10;
        }
        .st47 {
          fill: #231F20;
          stroke: #231F20;
          stroke-width: 0.25;
          stroke-miterlimit: 10;
        }
      }
    }
    .suite-level {
      svg {
        .st0 {
          fill: #EDEDED;
        }
        .st1 {
          fill: #DCDBDB;
          &.focused {
            fill: #c41230;
          }
        }
        .st2 {
          fill: none;
          stroke: #BCBCBC;
          stroke-width: 2;
          stroke-miterlimit: 10;
        }
        .st3 {
          fill: $white;
        }
        .st4 {
          fill: #003767;
        }
        .st5 {
          fill: #CE152D;
        }
        .st6 {
          fill: #C41230;
        }
        .st7 {
          fill: #FFCF01;
        }
        .st8 {
          fill: #3D3D3D;
        }
        .st9 {
          font-family: 'CharlotteSansBoldPlain';
        }
        .st10 {
          font-size: 15px;
        }
        .st11 {
          font-size: 29.9606px;
        }
        .st12 {
          fill: #F7F7F7;
        }
        .st13 {
          fill: none;
          stroke: #EDEDED;
          stroke-width: 4;
          stroke-miterlimit: 10;
        }
        .st14 {
          fill: #034C82;
        }
        .st15 {
          font-size: 20px;
        }
        .st16 {
          fill: #8C8C8C;
        }
        .st17 {
          fill: none;
          stroke: #3D3D3D;
          stroke-width: 4;
          stroke-miterlimit: 10;
        }
        .st18 {
          fill: #DCDBDB;
          stroke: #EDEDED;
          stroke-width: 4;
          stroke-miterlimit: 10;
        }
        .st19 {
          fill: $gold;
          &.focused {
            fill: #c41230;
          }
        }
      }
    }
  }
}

@media(max-width: 768px) {
  .concourse-map {
    .page-title {
      width: 100%;
    }

    &__search {
      display: none;
    }

    .sectionRight {
      width: auto;
      position: relative;
      right: 0;
      top: 0;
      border-left: 0px solid #eeeeee;
    }

    .selectConcourseMap {
      margin-top: -28px;
      width: 100%;
    }

    .footer {
      position: relative;
      width: auto;
      bottom: 0;
    }
  }
}

@media only screen and (max-width: 700px) {
  .concourse-map {
    .selectWrapper {
      display: block;
      margin-top: -28px;
    }

    .selectConcourseMap {
      display: none;
    }
  }
}