.page-title {
  align-items: center;
  background: $dark-grey;
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  
  h2 {
    color: $white;
    font-family: 'Oswald-Regular';
    font-size: 18px;
    font-weight: normal;
    margin: 0;
    text-transform: uppercase;
  }

  img {
    cursor: pointer;
    display: block;
    height: 20px;
    width: 20px;
  }
}