.parking-map {
  background: $white;
  
  &__copy {
    font-family: 'CharlotteSansMediumPlain';
    padding: 10px 20px;
  }

  &__button {
    align-items: center;
    background: $dark-grey;
    color: $white;
    display: flex;
    font-family: 'CharlotteSansBoldPlain';
    font-size: 18px;
    justify-content: space-between;
    margin: 0 20px 30px;
    padding: 10px 15px;
    text-decoration: none;

    span,
    img {
      display: block;
    }

    img {
      max-height: 35px;
    }
  }

  &__image {
    margin-bottom: 50px;
    width: 100%;

    img {
      display: block;
      padding: 0 30px;
      width: 100%;
    }
  }
}

@media (max-width: 370px) {
  .parking-map {
    &__button {
      font-size: 16px;
    }
  }
}

@media (max-width: 340px) {
  .parking-map {
    &__button {
      text-align: center;

      span {
        width: 100%;
      }

      img {
        display: none;
      }
    }
  }
}
