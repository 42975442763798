.eventsRodeo {
  background-color: $white;

  .selectWrapper {
    margin: -10px auto 0;
    width: calc(100% - 60px);
    max-width: 600px;
  }

  .athleteContainer {
    padding: 20px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    .athleteCard {
      border: solid 1px $grey;

      .top {
        .imgContainer {
          display: inline-block;
          width: 30%;

          img {
            margin-bottom: -5px;
            max-width: 100%;
          }
        }

        .content {
          display: inline-block;
          padding: 10px;
          vertical-align: top;
          width: 70%;

          h3 {
            font-family: 'Oswald-Light';
            font-size: 18px;
            margin: 0;
          }

          p {
            font-family: 'CharlotteSansMediumPlain';
            font-size: 14px;
            margin: 0;
          }
        }
      }

      .bottom {
        background-color: $dark-grey;
        padding: 0 15px;

        p:first-child {
          color: $white;
          display: inline-block;
          font-family: 'Oswald-Light';
          font-size: 18px;
          margin: 10px 0px;
          width: 50%;
        }

        p:last-child {
          color: $gold;
          display: inline-block;
          font-family: 'Oswald-Regular';
          font-size: 18px;
          margin: 10px 0px;
          text-align: right;
          width: 50%;
        }
      }
    }
  }
}

.breadcrumb {
  font-size: 14px;
  margin: 0;
  padding: 0 30px;
  color: #6b6b6d;

  a {
    color: $grey;
    display: inline-block;
    margin-bottom: 15px;
    margin-right: 5px;
    text-decoration: none;

    &:after {
      content: '>';
      display: inline-block;
      margin-left: 5px;
    }

    &:last-child:after {
      content: '';
    }
  }
}

.scheduleWrapper,
.athletesWrapper {
  background-color: $white;
  font-family: 'CharlotteSansMediumPlain';

  .mastheadContainer {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    background-color: $dark-grey;

    .masthead {
      background-color: $dark-grey;

      .page-title {
        padding-bottom: 5px;
      }
    }

    .eventExtras {
      padding-bottom: 10px;

      .card {
        margin-bottom: 0;
        background-color: $white;

        p {
          color: $dark-grey;
        }
      }
    }
  }

  hr {
    border: 0;
    border-top: solid 1px #ccc;
    margin: 15px 20px;
  }

  .scheduleHeader {
    padding: 0 30px;
    padding-bottom: 15px;

    h2 {
      display: inline-block;
      line-height: 1;
      margin: 0;
      text-transform: uppercase;
    }

    span {
      color: $red;
      display: inline-block;
      float: right;
      font-family: 'CharlotteSansMediumPlain';
      font-size: 15px;
      margin-top: 7px;
      text-decoration: underline;
    }
  }

  .dateSlider {
    margin-bottom: 25px;
    margin-top: 10px;
    padding: 0 30px;

    .slick-current {
      .dateSliderDate {
        color: $gold;
      }
    }

    .dateSliderDate {
      color: $grey;
      text-align: center;

      .day {
        display: block;
        font-size: 16px;
        line-height: 1;
        margin-bottom: 10px;
      }

      .date {
        display: block;
        font-family: 'Oswald-Bold';
        font-size: 19px;
        line-height: 1;
      }

      &.active {

        .day,
        .date {
          color: $gold;
        }
      }
    }

    .slick-prev {
      color: $black;
      left: -20px;
      width: 20px;
    }

    .slick-next {
      color: $black;
      right: -20px;
      width: 20px;
    }

    .slick-prev:before,
    .slick-next:before {
      color: $black;
    }
  }

  .timeContainer {
    margin-bottom: 15px;
    padding: 0 30px;

    .time {
      display: inline-block;
      vertical-align: top;
      width: 100px;

      span {
        color: $grey;
        font-size: 14px;
      }
    }

    .event {
      border: solid 1px #979797;
      display: inline-block;
      padding: 10px 15px;
      width: calc(100% - 100px);

      p {
        margin: 0;
        margin-bottom: 5px;
        font-family: 'Oswald-Regular';
        font-size: 15px;
      }

      span {
        font-size: 13px;
      }
    }
  }
}

.bracketsWrapper {
  background-color: $white;
  font-family: 'CharlotteSansMediumPlain';

  .masthead {
    background-color: $dark-grey;

    .page-title {
      padding-bottom: 5px;
    }
  }

  .bracketsHeader {
    margin-top: 25px;
    padding: 0 30px;

    h2 {
      display: inline-block;
      font-family: 'Oswald-Regular';
      font-size: 18px;
      line-height: 1;
      margin: 0;
      text-transform: uppercase;
    }

    span {
      color: $red;
      float: right;
      font-family: 'CharlotteSansMediumPlain';
      font-size: 15px;
      margin-top: 1px;
      text-decoration: underline;
    }
  }

  .bracketsNav {
    padding: 0px 30px;
    position: absolute;
    top: 25px;
    left: 0;
    width: 100%;

    .bracketPrev {
      display: inline-block;
      font-size: 24px;
      vertical-align: middle;
      width: 40px;
    }

    .bracketNext {
      display: inline-block;
      font-size: 24px;
      text-align: right;
      vertical-align: middle;
      width: 40px;
      float: right;
    }
  }

  .bracketTableContainer {    
    position: relative;
    display: grid;
    margin-top: 30px;
    grid-gap: 0px;
    grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
    padding: 0 20px;

    .bracketNavContent {
      display: block;
      text-align: center;
  
      h3 {
        font-size: 21px;
        margin: 0;
        margin-bottom: 5px;
      }
    }

    .col {
      padding: 15px;

      &.active {
        background-color: #f7f7f7;
      }
    }
  } 

  .tableContainer {
    margin-bottom: 0;

    .tableHeader {
      color: $gold;
      font-family: 'Oswald-Regular';
      font-size: 16px;
      margin-bottom: 5px;
    }

    table {
      border-collapse: collapse;
      border: solid 1px #979797;
      width: 100%;

      tr {
        background-color: rgba(224, 224, 224, 0.84);
        border-bottom: solid 1px #979797;
        color: #c6c6c6;
        font-family: 'Oswald-Regular';
        font-size: 15px;

        &.green {
          background-color: rgba(33, 237, 18, 0.2);
          color: $dark-grey;
        }

        &.orange {
          background-color: rgba(255, 186, 0, 0.23);
          color: $dark-grey;
        }

        td {
          padding: 10px;

          &:last-child {
            font-family: 'Oswald-Light';
            text-align: right;
          }
        }
      }
    }
  }

  .footnote {
    color: $grey;
    margin-bottom: 20px;
    padding: 0 30px;

    p {
      margin-bottom: 8px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 8px;
        padding-left: 15px;
        position: relative;

        &::before {
          background-color: #ccc;
          content: '';
          display: block;
          height: 11px;
          left: 0;
          position: absolute;
          top: 3px;
          width: 11px;
        }

        &:first-child::before {
          background-color: #497d45;
        }

        &:nth-child(2)::before {
          background-color: #ebab00;
        }

        &:last-child::before {
          background-color: #ff7c00;
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .eventsRodeo {
    .selectWrapper {
      margin: -5px auto 0;
      width: calc(100% - 60px);
    }
  }
}