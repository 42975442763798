.concession {
  background-color: $dark-grey;
  color: $white;
  font-family: 'CharlotteSansMediumPlain';
  
  .modal__concourse {
    padding: 20px 30px;
  }

  .page-title {
    h2 {
      font-family: 'Oswald-Regular';
      // margin-top: 0;
      text-transform: uppercase;
    }

    a {
      color: $gold;
      text-decoration: none;
      border-bottom: 1px solid;
    }
  }
}