.sport {
  &__header {
    align-items: center;
    border-bottom: 1px solid $grey;
    display: grid;
    grid-template-columns: 30% 40% 30%;
    padding: 30px 20px;

    &__third {
      font-family: 'Oswald-Regular';
      text-align: center;
      text-transform: uppercase;

      img {
        max-height: 50px;
        max-width: 50px;
        padding-bottom: 10px;
      }
    }
  }

  &__nav {
    border-bottom: 1px solid lighten($grey, 30%);
    display: flex;
    justify-content: space-around;

    &__option {
      border-bottom: 4px solid $white;
      color: $grey;
      cursor: pointer;
      font-family: 'CharlotteSansMediumPlain';
      padding: 10px 0;
      width: 50%;
      text-align: center;
      transition: all .25s ease;

      &.active {
        border-bottom: 4px solid $gold;
        font-family: 'CharlotteSansBoldPlain';
        color: $black;
        font-weight: bold;
      }
    }
  }

  &__stats {
    padding: 20px;

    &__nav {
      display: grid;
      grid-template-columns: 50% 50%;
      text-align: center;

      div {
        border: 1px solid $dark-grey;
        cursor: pointer;
        font-family: 'CharlotteSansMediumPlain';
        padding: 10px 0;

        &.active {
          background: $dark-grey;
          color: $white;
        }
      }
    }

    &__body {
      margin: 20px 0;

      .info-expander {
        border-bottom: 1px solid $black;

        &__title {
          background: $white;
          padding: 15px 0;
        }

        &__copy {
          padding: 0;
        }
      }
    }

    &__table {
      margin-bottom: 10px;

      &__body {
        border-collapse: collapse;
        width: 100%;

        .numeric {
          text-align: right;
        }

        thead {
          tr {
            background: $gold;
            color: $white;
            font-weight: bold;

            td {
              padding: 5px;
            }
          }
        }

        tbody {
          tr {

            &:nth-child(even) {
              background: lighten($grey, 40%);
            }

            td {
              padding: 5px;
            }
          }
        }
      }
    }
  }

  &__rosters {
    padding: 20px;

    &__body {
      margin-top: 20px;
    }

    &__player {
      border-bottom: 1px solid $gold;
      font-family: 'CharlotteSansMediumPlain';
      padding: 5px 0;

      &:nth-child(odd) {
        background: -webkit-gradient(linear, left top, right top, from(darken($white, 5%)), to($white));
        background: linear-gradient(90deg, darken($white, 5%) ,$white);
      }

      &__intro {
        display: grid;
        grid-template-columns: 15% 65% 10% 10%;
      }

      &__extra {
        align-items: center;
        display: grid;
        grid-template-columns: 35% 55%;
        margin: 10px 0;
      }

      &__image {
        height: 100%;

        &--container {
          height: 120px;
          position: relative;
          width: 87px;
        }
      }

      &__chevron {
        cursor: pointer;
        padding-right: 10px;
        margin-top: 3px;
        text-align: right;

        &::before {
          border-style: solid;
          border-width: 2px 2px 0 0;
          content: "";
          display: inline-block;
          height: .45em;
          left: .15em;
          position: relative;
          top: .15em;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
          -webkit-transition: all .3s ease;
          transition: all .3s ease;
          vertical-align: top;
          width: .45em;
        }

        &.down {
          &::before {
            top: 0;
            -webkit-transform: rotate(-225deg);
            transform: rotate(-225deg);
          }
        }
      }
    }
  }
}